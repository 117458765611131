import React, { useState } from "react";
import style from "../Style/contact.module.css";
import { IoAddOutline } from "react-icons/io5";
import Button from "../Common/Button";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const btnSubmit = (e) => {
    e.preventDefault();
    const { name, email, message } = formData;
    // const mailtoLink = `mailto:foyinb@techstylers.com?subject=Contact from ${name}&body=Name: ${name}%0D%0AEmail: ${email}%0D%0AMessage: ${message}`;
    const mailtoLink = `mailto:sanzy4617@gmail.com?subject=Contact from ${name}&body=Name: ${name}%0D%0AEmail: ${email}%0D%0AMessage: ${message}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className={style.container}>
      <div className={style.imageContainer}>
        <div className={style.textOverlay}>
          <h1>Contact Us </h1>
          <p>
            Have a question or need assistance? Reach out to us, and we will be
            glad to help!
          </p>
        </div>
      </div>
      <div className={style.formContainer}>
        <div className={style.textContainer}>
          <h1>Get In Touch</h1>
          <p>
            Let's connect! Whether you have feedback, inquiries, or just want to
            say hello, don't hesitate to get in touch with us. We're eager to
            hear from you.
          </p>
        </div>
        <form onSubmit={btnSubmit}>
          <div className={style.formGroup}>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className={style.formGroup}>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className={style.formGroup}>
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              rows="8"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className={style.submit}>
            <Button
              className={"btnPry"}
              title={"Send Message"}
              btnEventHandler={btnSubmit}
            />
          </div>
        </form>
      </div>
      {/* <section className={style.formText}>
        <div className={style.textContainer}>
          <h1>Frequently Asked Questions</h1>
          <p>
            Our FAQs provide concise answers to help streamline your experience
            and address any uncertainties you may have.
          </p>
        </div>
        <div className={style.questionContainer}>
          <div className={style.question}>
            <div>
              <p>How Can I be a member of techStylers</p>
              <IoAddOutline className={style.plusSign} />
            </div>
            <hr />
          </div>
          <div className={style.question}>
            <div>
            <p>How Can I be a member of techStylers</p>
              <IoAddOutline className={style.plusSign} />
            </div>
            <hr />
          </div>
          <div className={style.question}>
            <div>
            <p>How Can I be a member of techStylers</p>
              <IoAddOutline className={style.plusSign} />
            </div>
            <hr />
          </div>
          <div className={style.question}>
            <div>
            <p>How Can I be a member of techStylers</p>
              <IoAddOutline className={style.plusSign} />
            </div>
            <hr />
          </div>
          <div className={style.question}>
            <div>
            <p>How Can I be a member of techStylers</p>
              <IoAddOutline className={style.plusSign} />
            </div>
            <hr />
          </div>
          <div className={style.question}>
            <div>
            <p>How Can I be a member of techStylers</p>
              <IoAddOutline className={style.plusSign} />
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default Contact;
