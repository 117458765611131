import Jumoke from "../../Assets/Image/jumoke.png";
import Foyin from "../../Assets/Image/Foyin01.png";
import Blessing from "../../Assets/Image/Blessing.png";
import Taiwo from "../../Assets/Image/Taiwo.jpeg";
import Nihinlola from "../../Assets/Image/Nihinlola01.png";
import Lara from "../../Assets/Image/LaraTS01.png";
import Joy from "../../Assets/Image/Joy01.png";
import Aminas from "../../Assets/Image/Aminas.jpg";
import Azure from "../../Assets/Image/Azure.jpeg";
import Rachel from "../../Assets/Image/Rachel.jpeg";

export const teamMembers = [
  {
    img: Jumoke,
    name: "Olajumoke Toriola",
    title: "Co-Founder, Techstylers",
    description: `Olajumoke Toriola has had a distinguished 19-year career in technology leadership and is well-known for her significant strides in promoting diversity and inclusion in the tech field. As a co-founder of TechStylers, she is committed to advancing women in technology by providing comprehensive training and mentorship to create a more equal industry. Her expertise is evident in her motivational speaking and numerous accolades, including being named a Microsoft Most Valuable Professional (MVP), Dynamics User Group (DUG) Brand Builder, and her role in the Microsoft Regional Director program. Olajumoke’s leadership, advocacy, and esteemed recognition highlight her unwavering dedication to advancing the tech industry.`,
  },
  {
    img: Foyin,
    name: "Foyin Olajide-Bello",
    title: "Co-Founder, Techstylers",
    description: `Foyin founded Techstylers with the vision of creating a community where women could learn, grow and connect in tech. Foyin is a  passionate, self-driven Tech Leader who has an insatiable desire to make a difference for the better. She lives with the quote “I  need to leave it better than I met it” and has translated this into every area of her life. She has over 15 years of experience in the tech industry. Foyin is a Microsoft MVP and Regional Director.`,
  },
  {
    img: Nihinlola,
    name: "Nihinlola Adeyemi",
    title: "Global President, TechStylers",
    description: ` Nihinlola Adeyemi is a distinguished leader in the tech industry with over two decades of experience in Digital Transformation, Cybersecurity, IT Governance, and Product Management. She holds a degree in Computer Science, a postgraduate degree, and an Executive MBA from CEIBS.  As a member of the Forbes Technology Council, a certified Google Mentor, and a member of ADPList Mentors Club, Nihinlola collaborates with top tech executives globally. She is the author of "Impact of Corporate Governance on Scrumban" and is a sought-after speaker and mentor. At TechStylers, Nihinlola focuses on empowering women in tech  through free training and mentorship. Her visionary leadership inspires meaningful change, fostering collaboration and continuous improvement in the tech space.`,
  },
  {
    img: Joy,
    name: "Joy Emedom",
    title: "Head, Communications",
    description: `Joy is a Tech Leader with over a decade in the industry. She is passionate about helping people get into Tech and does this through volunteering in mentoring and coaching for the community. One of her favourite mantra is “the only thing stopping you is  YOU”.`,
  },
  {
    img: Lara,
    name: "Omolara Ajijola",
    title: "Head, Programs",
    description: `  Lara is a Product Manager who became a part of the Techstylers team as a participant. She is passionate about human connections and, as a result, strives to enrich the lives of those she interacts with and learn new things in the process. She collaborates with cross-functional teams to align community  programs.`,
  },
  {
    img: Azure,
    name: "Azure",
    title: "Head Global Partnerships & Growth",
    description: ` Azure is a Microsoft Business Applications MVP and Senior Consultant in the Tech Industry. She drives digital transformation for techies and customers, one Power Platform solution at a time. Outside of work, I'm actively involved in helping underrepresented individuals get a find their path in tech, providing them empowerment through education, continuous support, and the opportunity to thrive.`,
  },
  {
    img: Blessing,
    name: "Blessing Ogechi Nwojiji",
    title: "Community Manager",
    description: ` Blessing is passionate about having fun while learning and helping others grow, which draws her to the TechStylers community. She has significantly contributed to various local and international communities, fostering growth and making a positive impact. As a dynamic Community Manager with experience in leading tech companies, Blessing teaches Data Analysis using Python and is a certified AWS Solutions Architect. Her analytical prowess and strong interpersonal skills make her a dependable asset, consistently delivering results, fostering growth, and bridging the gap between technical nuances and community needs.`,
  },
  {
    img: Aminas,
    name: "Amina Gamaliel",
    title: "",
    description: `Amina is a tech enthusiast passionate about the intersection of design and technology. Her journey began in 2018 with graphic design, where she developed a love for creating visually compelling content. In 2020, she transitioned into UI/UX design through free training programs for women in tech. This shift allowed her to blend her artistic background with user-centered principles, resulting in meaningful digital experiences. In 2021, Amina became a UI/UX Mentor and Educator with the TechStylers Community. This role has been an incredible growth opportunity, allowing her to share her knowledge and passion for design with others, while continually learning and evolving in the tech field. Currently, Amina is exploring animation with the goal of becoming a Virtual Reality (VR) Designer. This new venture excites her as it represents the next frontier in creating immersive and interactive digital experiences. She is committed to continuous learning and growth in the tech field, aiming to push the  boundaries of design and technology.`,
  },
  {
    img: Taiwo,
    name: "Taiwo Eunice Oyinbooke",
    title: "Head, Social Media",
    description: `Taiwo is a Digital Creator whose creativity is centered on her day to day activities. She hopes for her audience to see the world through her lenses using vlogs, talks and lifestyle.`,
  },
  {
    img: Rachel,
    name: "Rachel Irabor ",
    title: "Training Coordinator",
    description: `Rachel has years of experience in the digital innovation industry. She is passionate about technology evangelism, community building and advocating for opportunities in the industry by sharing resources, volunteering, mentorship and hosting events.`,
  },
  // Add other team members similarly
];
