export const articleData = [
  {
    id: 6,
    tag: "Tech Trends",
    readTime: "5 mins read",
    title: "Human Skills in AI Integration",
    published: "4 hours ago",
  },
  {
    id: 7,
    tag: "Tech Trends",
    readTime: "5 mins read",
    title: "Social Media Transformation",
    published: "4 hours ago",
  },
  {
    id: 8,
    tag: "Tech Trends",
    readTime: "5 mins read",
    title: "Electric Vehicles (EVs) and Charging Infrastructure",
    published: "4 hours ago",
  },
  {
    id: 2,
    tag: "Tech Trends",
    readTime: "5 mins read",
    title: "Voice-Activated Technology",
    published: "4 hours ago",
  },
  {
    id: 1,
    tag: "Career Advice",
    readTime: "7 mins read",
    title: "Embrace Continuous Learning with TechStylers’ Programs",
    published: "Jan 22, 2024",
  },
  {
    id: 9,
    tag: "Career Advice",
    readTime: "7 mins read",
    title: "Participate in The Tech Deep Dive for Hands-On Experience",
    published: "Jan 22, 2024",
  },
  {
    id: 3,
    tag: "Career Advice",
    readTime: "7 mins read",
    title: "Leverage the Power of Networking through TechStylers’ Community",
    published: "Jan 22, 2024",
  },
  {
    id: 4,
    tag: "Career Advice",
    readTime: "7 mins read",
    title: "Find a Mentor within TechStylers",
    published: "Jan 22, 2024",
  },
  {
    id: 5,
    tag: "Career Advice",
    readTime: "7 mins read",
    title: "Stay Curious and Innovative with TechStylers’ Learning Tracks",
    published: "Jan 22, 2024",
  },
];
