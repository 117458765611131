import React, { useState } from "react";
import Modal from "react-modal";
import style from "../Style/Team.module.css";
import Title from "../Common/Title";
import { teamMembers } from "../Misc/teamData";



const Team = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  const openModal = (member) => {
    setSelectedMember(member);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedMember(null);
    setModalIsOpen(false);
  };

  return (
    <div className={style.teamContainer}>
      <Title className={"titlePry"} title={"Our Leaders"} />
      <p className={style.teamTxt}>
        Meet the leaders supporting the community daily to grow and flourish.
      </p>

      <div className={style.teamMembers}>
        {teamMembers.map((member, index) => (
          <div key={index} className={style.teamMember}>
            <div className={style.teamImage}>
              <img src={member.img} alt="" className={style.teamImg} />
            </div>
            <div className={style.teamMemberInfo}>
              <h4 className={style.name}>{member.name}</h4>
              <p className={style.title}>{member.title}</p>
              <p className={style.description}>
                {member.description.substring(0, 100)}...
              </p>
              <span onClick={() => openModal(member)} className={style.readMoreButton}>
                Read More
              </span>
            </div>
          </div>
        ))}
      </div>

      {selectedMember && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Member Details"
          className={style.modal}
          overlayClassName={style.modalOverlay}
        >
          <div className={style.modalContent}>
            <h4 className={style.name}>{selectedMember.name}</h4>
            <p className={style.title}>{selectedMember.title}</p>
            <p className={style.description}>{selectedMember.description}</p>
            <button onClick={closeModal} className={style.closeButton}>
              Close
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Team;
