import event2 from '../../Assets/Image/Event2.png';
import event3 from '../../Assets/Image/Event3.png';
import event from "../../Assets/Image/Events image.png";
import EvolveCON from "../../Assets/Image/EvolveCON.png"
import TechDDT from "../../Assets/Image/TechDDT.png"
import MicrosoftSecurityTrack from "../../Assets/Image/MicrosoftSecurityTrack.jpeg"
import Copilot from "../../Assets/Image/Copilot.png"
import Figma from "../../Assets/Image/Figma.png"
import AppDev from "../../Assets/Image/AppDev.png"
import Cloud from "../../Assets/Image/Cloud.png"
import Data from "../../Assets/Image/Data.png"
import Inspiration24 from "../../Assets/Image/Inspiration24.png"
import DDCohort from "../../Assets/Image/DDCohort.png"

export const upcomingEvents = [
    {
        id: 1,
        image: TechDDT,
        title: "Tech Deep Dive Training",
        date: "May 18, 2024",
        description: "Join us for a hands-on training course in different tech tracks.",
        path: "https://www.youtube.com/@techstylers1285/playlists"
    },
    {
        id: 2,
        image: MicrosoftSecurityTrack,
        title: "Microsoft Security Track,",
        date: "May 18, 2024",
        description: "Participants will receive in-depth training on Microsoft Security solutions, including Microsoft Defender, Information Protection, and Purview's data lifecycle management, complemented by practical exercises and labs for real-world application. ",
        path: "https://www.youtube.com/watch?v=qYshRXnpANM&list=PLBRY-aSTLM_v7PCp6aAedkPnOhuCkOUIc&pp=iAQB"
    },
    {
        id: 3,
        image: Copilot,
        title: "Copilot for Power Platform & Copilot Studio Track",
        date: "May 18, 2024",
        description:"Participants for the Copilot for Power Platform & Copilot Studio Track will gain practical skills for using AI tools, streamlining app development, automating workflows, and building intelligent chatbots, boosting efficiency and innovation in their organizations.",
        path: "https://www.youtube.com/watch?v=b3uiwBIU8Cw&list=PLBRY-aSTLM_s3Fa9GNpcXOfaZ6hMkloBv&pp=iAQB"
    },
    {
        id: 4,
        image: Figma,
        title: "UI/UX - Figma Track",
        date: "May 18, 2024",
        description:"Participants in the UI/UX - Figma Track will master Figma, learn fundamental UI/UX design principles, and develop prototyping skills. They will enhance collaboration techniques, build a strong portfolio, and learn industry best practices from experts.",
        path: "https://www.youtube.com/watch?v=S2wWMSWxPPs&list=PLBRY-aSTLM_u64xanseHrBk30-5TxAllw&pp=iAQB"
    },
    {
        id: 5,
        image: Data,
        title: "Data- Power BI Track",
        date: "May 18, 2024",
        description:"The Data-Power BI Track teaches practical Power BI skills for data analytics and visualization. Participants will learn how to create interactive dashboards, transform data, and gain actionable insights for data-driven decisions.",
        path: "https://www.youtube.com/watch?v=i3N_0LCYzN4&list=PLBRY-aSTLM_uwTypaGdiJErAX6rlLuBWn&pp=iAQB"
    },
    {
        id: 6,
        image: AppDev,
        title: "App Dev - Power App  & Power Automate",
        date: "May 18, 2024",
        description:"Participants will gain deep knowledge and practical skills in using the Power Platform, with a focus on Power Apps and Power Automate. This empowers participants to solve real-world problems efficiently and innovatively.",
        path: "https://www.youtube.com/watch?v=q_9G20cEZyM&list=PLBRY-aSTLM_sZnkInIE7HcCVysz42zHUg&pp=iAQB"
    },
    {
        id: 7,
        image: Cloud,
        title: "Cloud Computing with Microsoft Azure",
        date: "May 18, 2024",
        description:"Participants will gain fundamental knowledge of Azure, practical skills in deploying and managing applications, hands-on experience, and certification preparation. They will learn Azure security best practices, connect with industry professionals, and access valuable resources, enhancing their career prospects.",
        path: "https://www.youtube.com/watch?v=byCxf9EdJ2Y&list=PLBRY-aSTLM_vmraw231kFZYWJQLBI2j-M&pp=iAQB"
    },
  

]

export const pastEvents = [
    {
        id: 1,
        image: EvolveCON,
        title: "TechStylers EvolveCON",
        date: "Oct 21, 2023 at 10am - 3:00pm ",
        description: "Members can connect with professionals who share similar interests in Microsoft 365. ",
         path: "https://www.youtube.com/@techstylers1285"
    },
    {
        id: 2,
        image: event2,
        title: "Tech Deep Dive Cohort 4 ",
        date: "March, 2023",
        description: "Members can connect with professionals who share similar interests in Microsoft 365. ",
         path: "https://www.youtube.com/watch?v=fHAAqv7hhIw"
    },
    {
        id: 3,
        image: event3,
        title: "Tech Deep Dive Cohort 3 ",
        date: "Feb 5, 2022",
        description: "Members can connect with professionals who share similar interests in Microsoft 365. ",
         path: "https://www.youtube.com/watch?v=jqchpXQwVVM"
    },
    {
        id: 4,
        image: DDCohort,
        title: "Tech Deep Dive Cohort 2 ",
        date: "Sept 19, 2021",
        description: "Members can connect with professionals who share similar interests in Microsoft 365. ",
         path: "https://www.youtube.com/watch?v=55fmKBCfUE8"
    },
    {
        id: 5,
        image: event,
        title: "Inspirational Session",
        date: "August, 2023",
        description: "Members can connect with professionals who share similar interests in Microsoft 365. ",
         path: "https://www.youtube.com/watch?v=HAOlXr2M1PQ"
    },
    {
        id: 6,
        image: Inspiration24,
        title: "Inspirational Session IWD Special Edition",
        date: "March, 2023 ",
        description: "Members can connect with professionals who share similar interests in Microsoft 365. ",
         path: "https://www.youtube.com/watch?v=HAOlXr2M1PQ"
    },
   
]

