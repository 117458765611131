import React from "react";
import blog from "../Style/About.module.css";
import blog1 from "../Style/Blog.module.css";
import aboutImage from "../../Assets/Image/About us image.png";
import about from "../../Assets/Image/services33.png";
import { blogPosts } from "../Misc/blogData";
import { careerPosts } from "../Misc/blogData";

const Blog = () => {
  return (
    <div className={blog.containerBlog}>
      <div className={blog.container}>
        <div className={blog1.container1}>
          <div className={blog1.containerimg}>
            <img src={aboutImage} alt="About Us" width="700px" />
            <div className={blog1.imageContent}>
              <h6>Featured</h6>
              <h2>
                Exploring the Latest Tech Trends in 2024: A Comprehensive Guide
                for TechStylers
              </h2>
              <p>
                Welcome to the future of technology! At TechStylers, we are
                committed to keeping our community informed and inspired by the
                latest innovations shaping our world. As we dive into 2024,
                let's explore some of the most exciting tech trends that are set
                to revolutionize industries and enhance our daily lives. This
                comprehensive guide will not only highlight these trends but
                also show how TechStylers is at the forefront of embracing these
                advancements
              </p>
            </div>
          </div>
          <div className={blog1.blogcontent}>
            <h3>Recent Blog Posts</h3>
            <div className={blog1.blogCards}>
              {blogPosts.map((post, index) => (
                <div key={index} className={blog1.blogCard}>
                  <div className={blog1.cardTime}>
                    <p>{post.category}</p>
                    <div className={blog1.circle}></div>
                    <span>{post.readTime}</span>
                  </div>
                  <div className={blog1.cardText}>
                    <h4>{post.title}</h4>
                  </div>
                  <div className={blog1.blogTime}>
                    <p>{post.time}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={blog1.container2}>
          <div className={blog1.articleCard}>
            <div className={blog1.cardTime}>
              <p>Tech Trend</p>
              <div className={blog1.circle}></div>
              <span> 5 mins read</span>
            </div>
            <div className={blog1.cardText}>
              <h4>Exciting Tech Trends Shaping 2024</h4>
            </div>
            <div className={blog1.blogTime1}>
              <p>4 hours ago</p>
            </div>
            <div className={blog1.articleBody}>
              <p>
                As we delve deeper into 2024, the tech landscape is brimming
                with innovations that promise to transform how we live, work,
                and interact. At TechStylers, we're always at the forefront of
                these trends, ensuring our community stays informed and
                inspired. Here are some of the most exciting tech trends of the
                year that are poised to make significant impacts:
              </p>
              <p>
                1. Generative AI and Data Security Generative AI continues to
                evolve, offering advanced capabilities that extend beyond
                creating content to enhancing data security. By leveraging
                machine learning, AI systems can now better understand normal
                data patterns and flag anomalies, thereby providing an extra
                layer of protection against potential threats. This is crucial
                as businesses handle increasing volumes of data and need robust
                security measures (
                <a href="https://technologymagazine.com/top10/top-10-technology-trends-for-2024">
                  Home of Technology News
                </a>
                ).
              </p>
              <p>
                2. The Rise of Wearable Tech: Vision Pro Apple's Vision Pro
                headset is set to revolutionize the wearable tech market.
                Expected to launch in early 2024, this high-end device offers
                mixed reality experiences, blending the real world with
                computer-generated visuals. While it's a significant investment,
                the Vision Pro represents a leap forward in spatial computing,
                potentially paving the way for more affordable and widely
                adopted versions in the near future (
                <a href="https://www.techradar.com/tech/ai-vision-pro-evs-and-more-know-these-10-tech-trends-and-be-the-smartest-person-in-the-room-in-2024">
                  TechRadar
                </a>
                ).
              </p>
              <p>
                3. Quantum Computing Advancements Quantum computing is making
                strides with post-quantum cryptography (PQC) and quantum
                networking. PQC is being integrated into conventional systems to
                safeguard data against future quantum attacks, while quantum
                networking is expected to enable more scalable quantum
                solutions. These advancements will be critical for industries
                with high data security demands, such as finance and government
                (
                <a href="https://technologymagazine.com/top10/top-10-technology-trends-for-2024">
                  Home of Technology News
                </a>
                ).
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={blog.container}>
        <div className={blog1.container1}>
          <div className={blog1.containerimg}>
            <img src={about} alt="About Us" width="700px" />
            <div className={blog1.imageContent}>
              <h6>Featured</h6>
              <h2>Empowering Women in Tech</h2>
              <p>
                In a world where technology continues to shape our future,
                TechStylers stands out as a beacon of empowerment for women in
                tech. Established in May 2020, TechStylers was born from the
                desire to increase women’s representation in tech communities.
                More than just a learning hub, it is a community of women who
                harness their technology skills to solve real-life problems and
                support each other in their tech careers.
              </p>
            </div>
          </div>
          <div className={blog1.blogcontent}>
            <h3>Recent Blog Posts</h3>
            <div className={blog1.blogCards}>
              {careerPosts.map((post, index) => (
                <div key={index} className={blog1.blogCard}>
                  <div className={blog1.cardTime}>
                    <p>{post.category}</p>
                    <div className={blog1.circle}></div>
                    <span>{post.readTime}</span>
                  </div>
                  <div className={blog1.cardText}>
                    <h4>{post.title}</h4>
                  </div>
                  <div className={blog1.blogTime}>
                    <p>{post.time}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={blog1.container2}>
          <div className={blog1.articleCard}>
            <div className={blog1.cardTime}>
              <p>Career Advice</p>
              <div className={blog1.circle}></div>
              <span> 5 mins read</span>
            </div>
            <div className={blog1.cardText}>
              <h4>TechStylers: Empowering Women in Tech</h4>
            </div>
            <div className={blog1.blogTime1}>
              <p>4 hours ago</p>
            </div>
            <div className={blog1.articleBody}>
              <p>
                <strong>Monthly Inspirational Sessions</strong>
                <br />
                TechStylers hosts monthly Inspirational sessions, providing a
                platform for women to share their experiences, challenges, and
                triumphs. These sessions are designed to motivate and inspire
                participants, fostering a sense of belonging and community. By
                hearing from successful women in tech, attendees gain valuable
                insights and encouragement to pursue their own tech journeys.
                <br />
              </p>
              <p>
                <strong>The Tech Deep Dive</strong>
                <br />
                One of the hallmark events of TechStylers is The Tech Deep Dive.
                Held annually, this hands-on tech meeting spans six weeks,
                including five weeks of intensive classes and one week of
                project presentations. Participants can choose from various
                learning tracks such as Microsoft Azure (Cloud Computing), Data
                Analytics (Power BI), Microsoft Data Security, App Development
                and Automation, Copilot for Copilot Studio, and UI/UX design
                using Figma.
                <br />
              </p>
              <p>
                <strong>Mentorship and Support</strong>
                <br />
                At the heart of TechStylers is its mentorship program. Mentors
                within the community play a crucial role in supporting other
                women in their tech-learning journeys. This peer-to-peer support
                system fosters a collaborative environment where women can learn
                from each other, share resources, and build lasting professional
                relationships.
                <br />
              </p>
              <p>
                <strong>Empowering Women</strong>
                <br />
                TechStylers is more than just a learning community it is a
                platform for women to empower themselves and others. By
                providing opportunities for skill development, networking, and
                mentorship, TechStylers helps women build confidence and achieve
                their career goals in technology.
              </p>
              <p>
                <strong>Career Advice and Success Stories</strong>
                <br />
                TechStylers also contributes to the broader tech community by
                writing articles on career advice for women aspiring to succeed
                in tech. These articles offer practical tips, insights, and
                strategies to navigate the tech industry. Additionally,
                TechStylers celebrates the achievements of women in its
                community by sharing their success stories. These stories not
                only inspire others but also highlight the impact of the
                TechStylers community.
              </p>
              <p>
                <strong>Conclusion</strong>
                <br />
                TechStylers is a vibrant community dedicated to increasing
                women’s representation in tech and supporting their growth.
                Through its inspirational sessions, annual tech events,
                mentorship program, and educational resources, TechStylers
                empowers women to excel in their tech careers and make a
                meaningful impact in the industry. Join TechStylers today and be
                part of a community that believes in the power of women in
                technology.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
