export const blogPosts = [
  {
    title: "Folding and Unfolding Devices",
    time: "4 hours ago",
    readTime: "5 mins read",
    category: "Tech Trend",
  },
  {
    title:
      "Telemedicine continues to grow, providing remote healthcare services.",
    time: "4 hours ago",
    readTime: "5 mins read",
    category: "Tech Trend",
  },
  {
    title:
      "Digital twins, virtual replicas of physical devices, are being used extensively in manufacturing and urban planning.",
    time: "4 hours ago",
    readTime: "5 mins read",
    category: "Tech Trend",
  },
  {
    title:
      "Voice-activated technology has improved significantly, enabling more accurate natural language processing.",
    time: "4 hours ago",
    readTime: "5 mins read",
    category: "Tech Trend",
  },
];

export const careerPosts = [
  {
    title: "Join Women-Focused Tech Communities like TechStylers",
    time: "4 hours ago",
    readTime: "5 mins read",
    category: "Career Advice",
  },
  {
    title: "Advocate for Yourself with TechStylers’ Guidance",
    time: "4 hours ago",
    readTime: "5 mins read",
    category: "Career Advice",
  },
  {
    title:
      "Gain Confidence and Resilience with TechStylers’ Empowerment Initiatives",
    time: "4 hours ago",
    readTime: "5 mins read",
    category: "Career Advice",
  },
  {
    title: "Develop Soft Skills through TechStylers’ Supportive Environment",
    time: "4 hours ago",
    readTime: "5 mins read",
    category: "Career Advice",
  },
];
