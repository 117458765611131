import React from "react";
import style from "../Style/Resources.module.css";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import TechDDT2 from "../../Assets/Image/TechDDT2.png";
import TechDDT3 from "../../Assets/Image/TechDDT3.png";
import TechDDT4 from "../../Assets/Image/TechDDT4.png";
import TechDDT5 from "../../Assets/Image/TechDDT5.png";
import TechDDT6 from "../../Assets/Image/TechDDT6.png";
import TechDDT7 from "../../Assets/Image/TechDDT7.png";
import TechDDT8 from "../../Assets/Image/TechDDT8.png";
import TechDDT9 from "../../Assets/Image/TechDDT9.png";
import TechDDT10 from "../../Assets/Image/TechDDT10.png";
import TechDDT11 from "../../Assets/Image/TechDDT11.png";
import TechDDT12 from "../../Assets/Image/TechDDT12.png";
import TechDDT13 from "../../Assets/Image/TechDDT13.png";
import TechDDT14 from "../../Assets/Image/TechDDT14.png";
import TechDDT15 from "../../Assets/Image/TechDDT15.png";
import TechDDT16 from "../../Assets/Image/TechDDT16.png";
import TechDDT18 from "../../Assets/Image/TechDDT18.png";
import TechDDT19 from "../../Assets/Image/TechDDT19.png";
import TechDDT20 from "../../Assets/Image/TechDDT20.png";
import evolve_1 from "../../Assets/Image/evolve_1.jpg";
import evolve_2 from "../../Assets/Image/evolve_2.jpg";
import evolve_3 from "../../Assets/Image/evolve_3.jpg";
import evolve_4 from "../../Assets/Image/evolve_4.jpg";
import LA0 from "../../Assets/Image/LA.jpg";
import LA1 from "../../Assets/Image/LA1.jpeg";
import LA2 from "../../Assets/Image/LA2.jpg";
import LA3 from "../../Assets/Image/LA3.jpg";
import LA4 from "../../Assets/Image/LA4.jpg";
import LA5 from "../../Assets/Image/LA5.jpg";
import LA6 from "../../Assets/Image/LA6.jpg";
import LA7 from "../../Assets/Image/LA7.jpg";
import LA8 from "../../Assets/Image/LA8.jpg";
import LA9 from "../../Assets/Image/LA9.jpg";
import LA10 from "../../Assets/Image/LA10.jpg";
import LA11 from "../../Assets/Image/LA11.jpg";
import LA12 from "../../Assets/Image/LA12.jpg";
import LA13 from "../../Assets/Image/LA13.jpg";
import LA14 from "../../Assets/Image/LA14.jpeg";
import LA15 from "../../Assets/Image/LA15.jpg";
import ladies from "../../Assets/Image/Ladies.jpg";
import LadiesinNorth from "../../Assets/Image/LadiesinNorth.jpg";




const Gallery = () => {
  return (
    <div className={style.container}>
      <div className={style.eventContainer}>
        <div className={style.txtLink}>
          <div className={style.txtIcon}>
            <p>Tech Mentors – Cohort 5</p>
            <MdOutlineKeyboardArrowUp className={style.icon} />
          </div>
          {/* <div>
            <a href="/" target="_blank" className={style.seeLink}>
              See All
            </a>
          </div> */}
        </div>
        <div className={style.cardGrid}>
          <div className={style.card}>
            <img src={TechDDT2} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={TechDDT3} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={TechDDT4} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={TechDDT5} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={TechDDT6} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={TechDDT7} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={TechDDT8} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={TechDDT9} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={TechDDT10} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={TechDDT11} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={TechDDT12} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={TechDDT13} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={TechDDT14} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={TechDDT15} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={TechDDT16} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={TechDDT18} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={TechDDT19} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={TechDDT20} alt="Resources Img" className={style.img} />
          </div>
        </div>
      </div>
      <div className={style.eventContainer}>
        <div className={style.txtLink}>
          <div className={style.txtIcon}>
            <p>Events</p>
            <MdOutlineKeyboardArrowUp className={style.icon} />
          </div>
          {/* <div>
            <a href="#" target="_blank" className={style.seeLink}>
              See All
            </a>
          </div> */}
        </div>
        <div className={style.cardGrid}>
          <div className={style.card}>
            <img src={evolve_1} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={evolve_2} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={evolve_3} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={evolve_4} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={LA0} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={LA1} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={LA2} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={LA3} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={LA4} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={LA5} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={LA6} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={LA7} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={LA8} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={LA9} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={LA10} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={LA11} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={LA12} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={ladies} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={LA13} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={LA14} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={LA15} alt="Resources Img" className={style.img} />
          </div>
          <div className={style.card}>
            <img src={LadiesinNorth} alt="Resources Img" className={style.img} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
