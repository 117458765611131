import React from "react";
import style from "../Style/landingPage.module.css";
import Button from "../Common/Button";
import hero1 from "../../Assets/Image/Hero_1.jpg";
import hero2 from "../../Assets/Image/Hero_2.jpg";
import hero3 from "../../Assets/Image/Hero_3.jpg";
import hero4 from "../../Assets/Image/Hero_4.jpg";
import { useState, useEffect } from "react";

const LandingPage = () => {
  const btnEventHandler = () => {
    window.open("https://forms.office.com/r/dxh3N8siHH", "_blank");
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const images = [hero1, hero2, hero3, hero4];

  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);

    return () => clearInterval(intervalId);
  }, [images]);
  return (
    <section className={style.container}>
      <div className={style.textContainer}>
        <h1 className={style.title}>
          Empowering <span>Women</span>
          <br />
          In <span>Technology</span>
        </h1>
        <p className={style.desc}>Breaking Barriers, Building Futures.</p>
        <div className={style.btnJoin}>
          <Button
            className={"btnPry"}
            title={"Join Now"}
            btnEventHandler={btnEventHandler}
            h
          />
        </div>
        <div className={style.bigTxt}>
          <div>
            <p className={style.big}>1500+</p>
            <p className={style.txt}>Members</p>
          </div>
          <div>
            <p className={style.big}>12+</p>
            <p className={style.txt}>Countries</p>
          </div>
          <div>
            <p className={style.big}>30+</p>
            <p className={style.txt}>Mentors</p>
          </div>
          <div>
            <p className={style.big}>150+</p>
            <p className={style.txt}>Tech Sessions</p>
          </div>
          <div>
            <p className={style.big}>30+</p>
            <p className={style.txt}>Inspirational Sessions</p>
          </div>
        </div>
      </div>
      <div className={style.imgContainer}>
        <div className={style.heroImg}>
          <img
            src={images[currentImage]}
            alt={`Hero Image ${currentImage + 1}`}
            className={style.logo}
          />
          <img
            src={images[(currentImage + 1) % images.length]}
            alt={`Hero Image ${((currentImage + 1) % images.length) + 1}`}
            className={style.logo}
          />
        </div>
      </div>
    </section>
  );
};

export default LandingPage;
